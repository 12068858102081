const propertyData = {
    "url": "http://895sw170thave.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "895 SW 170th Ave", "city": "Beaverton", "state":"Oregon", "zipcode": "97006"},
    "display_address": "895 SW 170th Ave",
    "property": {
      "listing_price": "$274,888",
      "bedrooms": "2",
      "baths": "1 Full / 2 Half",
      "sq_feet": "1144",
      "lot_size": "871.2",
      "rmls": "20363997",
      "built_in": "2005",
      "blurb": "Easy living in this great end unit townhome! Open floor plan on main level & gas fireplace. Great kitchen with bamboo floors, SS appliances, granite counter tops & eating bar. New carpet and A/C! Non conforming 3rd bedroom/den on first floor with half bath. Attached garage. 3 blocks to the Max station at Elmonica. Close to shopping & entertainment.",
      "ammenities": [

      ]
    },
    "image_count": 22,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.831128336291!2d-122.85488568444083!3d45.51347797910142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f1d819ca78b%3A0x1b9fa84cfa9d4ab5!2s895%20SW%20170th%20Ave%2C%20Beaverton%2C%20OR%2097006!5e0!3m2!1sen!2sus!4v1600367626367!5m2!1sen!2sus",
        "youtube":"iEn0Ehj6WyE",
        "matterport":"BP7gEaUQGCM"
    }
  }

export default propertyData;

